"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAssetNamespaceFromChainId = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const types_1 = require("@shapeshiftoss/types");
const assertUnreachable_1 = require("./assertUnreachable");
const getAssetNamespaceFromChainId = (chainId) => {
    switch (chainId) {
        case types_1.KnownChainIds.BnbSmartChainMainnet:
            return caip_1.ASSET_NAMESPACE.bep20;
        case types_1.KnownChainIds.SolanaMainnet:
            return caip_1.ASSET_NAMESPACE.splToken;
        case types_1.KnownChainIds.EthereumMainnet:
        case types_1.KnownChainIds.AvalancheMainnet:
        case types_1.KnownChainIds.OptimismMainnet:
        case types_1.KnownChainIds.PolygonMainnet:
        case types_1.KnownChainIds.GnosisMainnet:
        case types_1.KnownChainIds.ArbitrumMainnet:
        case types_1.KnownChainIds.ArbitrumNovaMainnet:
        case types_1.KnownChainIds.BaseMainnet:
            return caip_1.ASSET_NAMESPACE.erc20;
        case types_1.KnownChainIds.CosmosMainnet:
        case types_1.KnownChainIds.ThorchainMainnet:
            return caip_1.ASSET_NAMESPACE.ibc;
        case types_1.KnownChainIds.BitcoinMainnet:
        case types_1.KnownChainIds.BitcoinCashMainnet:
        case types_1.KnownChainIds.DogecoinMainnet:
        case types_1.KnownChainIds.LitecoinMainnet:
            throw Error(`Unhandled case '${chainId}'`);
        default:
            return (0, assertUnreachable_1.assertUnreachable)(chainId);
    }
};
exports.getAssetNamespaceFromChainId = getAssetNamespaceFromChainId;
