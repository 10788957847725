"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getChainShortName = void 0;
const types_1 = require("@shapeshiftoss/types");
const assertUnreachable_1 = require("./assertUnreachable");
const getChainShortName = (chainId) => {
    switch (chainId) {
        case types_1.KnownChainIds.AvalancheMainnet:
            return 'AVA';
        case types_1.KnownChainIds.OptimismMainnet:
            return 'OP';
        case types_1.KnownChainIds.EthereumMainnet:
            return 'ETH';
        case types_1.KnownChainIds.PolygonMainnet:
            return 'POLY';
        case types_1.KnownChainIds.GnosisMainnet:
            return 'GNO';
        case types_1.KnownChainIds.BnbSmartChainMainnet:
            return 'BNB';
        case types_1.KnownChainIds.ArbitrumMainnet:
            return 'ARB';
        case types_1.KnownChainIds.ArbitrumNovaMainnet:
            return 'ARB-Nova';
        case types_1.KnownChainIds.BaseMainnet:
            return 'BAS';
        case types_1.KnownChainIds.BitcoinMainnet:
            return 'BTC';
        case types_1.KnownChainIds.BitcoinCashMainnet:
            return 'BCH';
        case types_1.KnownChainIds.CosmosMainnet:
            return 'COSM';
        case types_1.KnownChainIds.ThorchainMainnet:
            return 'THOR';
        case types_1.KnownChainIds.DogecoinMainnet:
            return 'DOGE';
        case types_1.KnownChainIds.LitecoinMainnet:
            return 'LTC';
        case types_1.KnownChainIds.SolanaMainnet:
            return 'SOL';
        default: {
            (0, assertUnreachable_1.assertUnreachable)(chainId);
        }
    }
};
exports.getChainShortName = getChainShortName;
